<template>
  <ALink
    v-if="logoImage?.filename"
    :to="{ linktype: 'story', cached_url: url }"
    :ariaLabel="`Go to ${config?.title} homepage`"
    class="shrink-0 pointer-events-auto"
  >
    <AVideoTransparent
      v-if="animated && config?.logoAnimated?.[0]?.videoMp4?.filename"
      :videoMp4Url="config?.logoAnimated?.[0].videoMp4.filename"
      :videoWebmUrl="config?.logoAnimated?.[0].videoWebm.filename"
      :autoplay="true"
      :loop="false"
      :controls="false"
      class="opacity-100 transition-none clamp-w-28-56"
    />
    <AImage
      v-else
      :src="logoImage?.filename"
      :alt="config?.title"
      :focalPoint="logoImage?.focus"
      ratio="orig"
      :sizes="[
        {
          bpMin: '0',
          vw: 20,
        },
      ]"
      loading="eager"
      class="opacity-100 transition-none clamp-w-25-50"
    />
  </ALink>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';
import { AssetStoryblok } from '@/types/sb-components';

const props = withDefaults(
  defineProps<{
    animated?: boolean;
    logo?: 'main' | 'footer';
  }>(),
  {
    animated: false,
  },
);

const { config, brand } = useConfig();
const url = computed(() => {
  const isEditor = import.meta.env.MODE === 'editor';
  if (isEditor) return `/${brand.value}/`;
  return '/';
});

const logoImage = computed(() => {
  switch (props.logo) {
    case 'main':
      return config?.value?.logo;
    case 'footer':
      return config?.value?.footer?.[0]?.logo || config?.value?.logo;
    default:
      return config?.value?.logo; // Use main logo as default
  }
});
</script>
