<template>
  <nav
    class="flex flex-col items-center clamp-gap-6-10 tl:flex-row tl:items-baseline"
    v-if="footer"
  >
    <div class="flex-1 flex flex-col clamp-gap-4-7 items-center ml:items-start">
      <div
        class="flex flex-col items-center tl:flex-row justify-between w-full clamp-gap-5-15"
      >
        <BaseLogoBrand :logo="'footer'" />
        <BaseNavSoMeButtons size="lg" />
      </div>
      <BaseFooterNavButtons
        v-if="footer.buttons?.length"
        :buttons="footer.buttons"
      />
    </div>
  </nav>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';

const { config } = useConfig();
const footer = computed(() => config.value?.footer?.[0]);
</script>
