<template>
  <nav class="menu" :style="menuStyle">
    <ul class="menu-list" :class="{ 'text-center': columnCount === 1 }">
      <li v-for="link in navLinks" :key="link._uid">
        <ALink
          :to="link.link"
          class="menu-link pointer-events-auto"
          :class="$attrs.class"
          :style="$attrs.style"
          role="menuitem"
          @click="$emit('click', $event)">
          {{ link.label }}
        </ALink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { ElementNavLinkStoryblok } from "@/types";

interface Props {
  navLinks: ElementNavLinkStoryblok[];
}
const { navLinks } = defineProps<Props>();

defineEmits<{
  click: [value: MouseEvent | TouchEvent];
}>();

const width = ref(window.innerWidth);
const height = ref(window.innerHeight);
const aspectRatio = computed(() => width.value / height.value);

// Update on resize
const updateDimensions = () => {
  width.value = window.innerWidth;
  height.value = window.innerHeight;
};

onMounted(() => {
  window.addEventListener("resize", updateDimensions);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateDimensions);
});

// const maxItemsPerColumn = 5
// Taller screens allow more items per column
const maxItemsPerColumn = computed(() => {
  if (aspectRatio.value <= 0.75) {
    return 8;
  } else if (aspectRatio.value < 0.8) {
    return 7;
  } else if (aspectRatio.value < 1.0) {
    return 6;
  }
  return 5;
});

const columnCount = computed(() => {
  return Math.ceil(navLinks.length / maxItemsPerColumn.value);
});

const menuStyle = computed(() => ({
  "--column-count": `${columnCount.value}`,
}));
</script>

<style scoped>
.debug {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.menu-list {
  columns: clamp(1, var(--column-count), 3);
  column-width: max-content;
  column-gap: clamp(1rem, 4vw, 2rem);
}

.menu-list li {
  display: block;
  margin-bottom: clamp(1.5rem, 4vw, 2rem);
}
/* .menu-list li:last-child {
  padding-bottom: 0;
} */

@media (max-width: 1024px) {
  .menu-list {
    columns: clamp(1, var(--column-count), 2);
  }
}
@media (max-width: 568px) {
  .menu-list {
    columns: 1;
    text-align: center;
  }
}
</style>
