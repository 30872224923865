<template>
  <Transition name="fade">
    <BaseNavOverlay
      v-if="navOpen"
      @close="closeNav"
      :hasLogo="!!logoImage?.filename"
    />
  </Transition>
  <header
    aria-label="Header"
    v-if="header"
    class="fixed top-0 w-full z-header"
    :class="{ 'hide-header': hideHeader, 'nav-visible': navOpen }"
  >
    <SbCandleBanner class="relative z-header mt--1px hide-header-on-scroll" />
    <AGradientBlur
      class="absolute left-0 clamp-top-8-12 clamp-h-48-96 z-header-gradient translate-y--1px hide-header-on-scroll"
      direction="to bottom"
      :grayscale="true"
      :strength="0.5"
    />
    <div class="absolute w-full z-header text-white pointer-events-none">
      <nav
        class="page-mx h-header flex items-center"
        aria-label="Primary navigation"
      >
        <div class="flex flex-1 clamp-gap-8-8 items-center">
          <div class="hidden" role="menubar" aria-label="Main">
            <BaseNavLinks
              v-if="header.navLinks"
              :nav-links="header.navLinks"
              class="text-body-md font-bold"
            />
          </div>
        </div>
        <BaseLogoBrand
          class="z-header-above hide-header-on-scroll"
          :animated="true"
          :logo="'main'"
          @click="closeNav"
        />
        <div class="flex grow-1 shrink-0 basis-0 justify-end">
          <BaseNavOverlayToggle
            v-if="header.navLinks?.length"
            :is-open="navOpen"
            class="z-header-above transform-nav-overlay-toggle"
            @click="toggleNav"
          />

          <BaseNavSoMeButtons
            v-if="!header?.navLinks?.length"
            class="flex-1 justify-end hide-some-on-scroll max-tl:hidden"
          />
        </div>
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import useConfig from '@/composables/useConfig';
import { useScrollLock, useWindowSize } from '@vueuse/core';
import { getBreakpoint } from '@/../uno-preset/preset-theme';
import { useHeader } from '@/composables/useHeader';

const { config } = useConfig();
const { width: windowWidth } = useWindowSize();
const { hideHeader } = useHeader();

const header = computed(() => config.value?.header[0]);
const logoImage = computed(() => config.value?.logo || null);

const el = ref<HTMLElement | null>(null);
const isLocked = useScrollLock(el);
const navOpen = ref(false);

const openNav = () => {
  navOpen.value = true;
  isLocked.value = true;
};
const closeNav = () => {
  navOpen.value = false;
  isLocked.value = false;
};
const toggleNav = () => {
  if (navOpen.value) closeNav();
  else openNav();
};

watch(windowWidth, (newWidth) => {
  if (newWidth >= getBreakpoint('nav')) closeNav();
});

onMounted(() => {
  el.value = document.querySelector('body');
});
</script>

<style lang="scss">
// animation: navigation bar direction hide/show
html[data-scrolldir='down'] {
  .hide-header-on-scroll {
    transition: transform 1.5s ease-out;
    transform: translateY(-500%) !important;
  }
  .transform-nav-overlay-toggle {
    transition: transform 1s ease-out;
    transform: translateY(-80%) !important;
    &:hover {
      transform: translateY(-80%) scale(1.05) !important;
    }
  }

  // Had to create a separate transition for the SoMe buttons as otherwise they were animating slower than the rest of the header
  .hide-some-on-scroll {
    transition: transform 0.3s ease-out;
    transform: translateY(-500%) !important;
  }
}

header {
  @apply transition-transform duration-500 ease-out;
  &.hide-header {
    // the header content is 350% of the height of actual <header> thus we need to move it up by 400% to ensure that even bigger logos are not visible
    transform: translateY(-400%) !important;
  }
  &.nav-visible {
    // the header with logo is visible when the menu is open
    .hide-header-on-scroll {
      transition: transform 0.5s;
      transform: translateY(0) !important;
    }
    .transform-nav-overlay-toggle {
      transform: translateY(0) !important;
      &:hover {
        transform: translateY(0%) scale(1.05) !important;
      }
    }
  }
}
@media (min-width: 768px) {
  // on bigger screens the overlay toggle is centered on the right side in the space between screen edge and the candle logo
  .z-header-above.transform-nav-overlay-toggle {
    @apply tp:clamp-mr-0-11 tl:clamp-mr-0-15 ds:clamp-mr-0-18;
    position: absolute;
    right: 0;
    top: 26%;
  }
}
</style>
